@import 'https://js.arcgis.com/4.20/@arcgis/core/assets/esri/themes/light/main.css';

body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button-icon {
  font-size: 1.5rem;
  padding-right: 0.5rem;
}

.sidebar-header-icon {
  font-size: 1.2rem;
  padding-right: 0.5rem;
}

.esri-ui-corner .esri-component {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 40px;
}

.esri-ui-corner {
  width: 100%;
}

.esri-search {
  width: 100%;
  height: 48px;
  border-radius: 24px !important;
}

.esri-search__container {
  padding: 0px 16px;
}

.esri-widget--button {
  border-radius: 40px;
  box-shadow: none;
  border-left: none;
}

.esri-input {
  border-radius: 16px;
}

.esri-locate {
  top: 0;
  right: 0;
  margin-top: 150px;
  margin-right: 15px;
  position: fixed;
  height: 56px;
  width: 56px;
}

.esri-locate .esri-icon {
  font-size: 24px;
}

.esri-zoom {
  top: 0;
  right: 0;
  margin-top: 225px;
  margin-right: 15px;
  position: fixed;
  width: 56px;
  height: 112px;
}

.esri-zoom .esri-widget--button{
  width: 56px;
  height: 56px;
  border: none !important;
}

.esri-zoom .esri-icon {
  font-size: 24px;
}

.esri-compass {
  top: 0;
  right: 0;
  margin-top: 225px;
  margin-right: 15px;
  position: fixed;
  height: 56px;
  width: 56px;
}

.esri-compass .esri-icon {
  font-size: 24px;
}

.esri-layer-list {
  background-color: white;
  width: 90%;
}

.esri-view .esri-view-surface--inset-outline:focus:after {
  outline: none !important;
}

.esri-menu {
  border-radius: 16px;
  margin-top: 9px;
}

.esri-menu__list-item {
  border-top: none;
  padding: 10px 20px;
  font-size: 16px;
}
